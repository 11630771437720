$bgcolorNow: $primary-medium;
$bgcolorHasActive: lighten(rgb(0, 110, 220), 30%);
$bgcolorActive: $primary;

.react-calendar {
  max-width: 100%;

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -.5em;

      >* {
        width: 50%;
        margin: .5em;
      }
    }
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;
    background-color: $primary-light;

    button {
      min-width: 44px;
      background: none;

      &:enabled {

        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
        }
      }

      &[disabled] {
        background-color: rgb(240, 240, 240);
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-size: .75em;

      &__weekday {
        padding: .5em;
      }
    }

    &__weekNumbers {
      font-weight: $font-weight-bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .75em;
        padding: calc(.75em / .75) calc(.5em / .75);
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(209, 0, 0);
        }

        &--neighboringMonth {
          color: rgb(117, 117, 117)
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em .5em;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: .75em .5em;
    background: none;

    &:disabled {
      background-color: rgb(240, 240, 240);
    }

    &:enabled {

      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--now {
      background: $bgcolorNow;

      &:enabled {

        &:hover,
        &:focus {
          background: darken($bgcolorNow, 10%);
        }
      }
    }

    &--hasActive {
      background: $bgcolorHasActive;

      &:enabled {

        &:hover,
        &:focus {
          background: lighten($bgcolorHasActive, 10%);
        }
      }
    }

    &--active {
      background: $bgcolorActive;
      color: white;

      &:enabled {

        &:hover,
        &:focus {
          background: lighten($bgcolorActive, 10%);
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}