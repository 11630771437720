html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: $font-family;
  color: $primary;
  font-size: 14px;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  ul,
  ol {
    padding-left: 16px;
  }
}

.secondary-border{
  border: 1px solid $secondary!important;
}