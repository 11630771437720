@import "~react-tabs/style/react-tabs";
@import "variables";

.react-tabs {
  &__tab-list {
    height: 36px;
    display: flex;
    white-space: nowrap;
    border-bottom: none;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 0 $spacing-lg;
  }

  &__tab {
    display: block;
    border: none;
    border-bottom: 1px solid transparent;
    font-weight: $font-weight-medium;
    font-size: 16px;

    &--selected {
      border-bottom: 2px solid $secondary;
      border-radius: initial;
      background: transparent;
    }

    &:focus {
      box-shadow: none;
      border-bottom: 1px solid transparent;
    }
  }
}
