input,
select {
  display: block;
  background: $white;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 $spacing-sm;
  margin: 0;
  height: 44px;
  flex-grow: 1;
  flex-shrink: 0;

  &:focus {
    outline: 0;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

select {
  padding: 0 $spacing-sm;

  option[value=''][disabled] {
    display: none;
  }
}

textarea {
  display: block;
  background: $white;
  color: $primary;
  box-sizing: border-box;
  border-radius: 5px;
  padding: $spacing $spacing-sm;
  margin: 0;
  min-height: 150px;
  width: 100%;
  font-family: $font-family;

  &:focus {
    outline: 0;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// http://danielstern.ca/range.css/#/
input[type='range'] {
  width: 100%;
  margin: 16px 0;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none;
  border-color: transparent;
}

input[type='range']::-webkit-slider-runnable-track {
  background: #00c8a0;
  border: 0;
  border-radius: 10px;
  width: 100%;
  height: 6px;
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  margin-top: -7.6px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #491f69;
  border-radius: 25px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #00c8a0;
}

input[type='range']::-moz-range-track {
  background: #00c8a0;
  border: 0;
  border-radius: 10px;
  width: 100%;
  height: 6px;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 3px solid #491f69;
  border-radius: 25px;
  cursor: pointer;
}

input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 8.5px 0;
  color: transparent;
  width: 100%;
  height: 4.8px;
  cursor: pointer;
}

input[type='range']::-ms-fill-lower {
  background: #00c8a0;
  border: 0;
  border-radius: 10px;
}

input[type='range']::-ms-fill-upper {
  background: #00c8a0;
  border: 0;
  border-radius: 10px;
}

input[type='range']::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 3px solid #491f69;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}

input[type='range']:focus::-ms-fill-lower {
  background: #00c8a0;
  border-radius: 10px;
}

input[type='range']:focus::-ms-fill-upper {
  background: #00c8a0;
  border-radius: 10px;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
