@import '../../../Styles/variables';

.big-calendar-component {
  .rbc-header {
    padding: 8px 4px;
  }

  .rbc-event-label {
    display: none;
  }

  &__global-event {
    background: $secondary;
    border: 1px solid $grey-dark !important;
    font-size: 13px !important;
    border-radius: 3px !important;
    padding: 4px !important;
    overflow: unset !important;

    &:hover {
      background: darken($secondary, 5) !important;
    }

    &_time-title {
      font-weight: 600;
      font-size: 10px;
      width: calc(100% - 20px);
    }

    &_patient-name {
      font-weight: 600;
      font-size: 9px;
      margin-top: 1px;
    }

    .rbc-events-container {
      width: 100%;
      .rbc-event {
        padding: 4px 8px !important;
        background-color: $grey-medium;
        border-radius: 8px;
      }
    }
  }

  .rbc-allday-cell {
    display: none !important;
  }

  .rbc-toolbar {
    position: relative;
  }

  .rbc-time-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    &:last-child {
      display: none;
    }
  }

  //.rbc-btn-group {
  //  position: initial;
  //  left: 0;
  //
  //  @media screen and (min-width: 650px) {
  //    //position: absolute;
  //  }
  //  button {
  //    font-weight: 700;
  //    background: $primary;
  //    color: white;
  //    cursor: pointer;
  //    border: 1px solid #857886;
  //
  //    &:hover {
  //      background: #733b77;
  //    }
  //  }
  //}

  .rbc-toolbar-label {
    font-size: 24px;
    font-weight: 700;
  }
}

.--big-calendar-component-event-type-availability {
  background: $grey-medium;
}
