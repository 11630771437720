h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-bold;
  line-height: 1.6;
}

h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.25em;
}

h1 {
  font-size: 24px;
  @media screen and (min-width: $break-md) {
    font-size: 30px;
  }
}

h2 {
  font-size: 20px;
  @media screen and (min-width: $break-md) {
    font-size: 24px;
  }
}

h3 {
  font-size: 18px;
  @media screen and (min-width: $break-md) {
    font-size: 20px;
  }
}

h4 {
  font-size: 16px;
  @media screen and (min-width: $break-md) {
    font-size: 18px;
  }
}

h5 {
  font-size: 15px;
  @media screen and (min-width: $break-md) {
    font-size: 16px;
  }
}
h6 {
  font-size: 13px;
  @media screen and (min-width: $break-md) {
    font-size: 14px;
  }
}

p {
  margin-top: 0;
  &.lead {
    font-size: 18px;
  }
}

a {
  text-decoration: underline;
}