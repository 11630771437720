table,
.table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: $spacing-md $spacing-sm;
    border-collapse: collapse;
    border-bottom: 1px solid #E5E5E5;
    text-align: left;
  }
  th {
    font-size: 17px;
  }
  &.compact {
    th, td {
      padding: $spacing-sm $spacing-sm;
    }
    th {
      font-size: inherit;
    }
  }
}

table.striped {
  tr {
    td,
    th {
      padding: 16px 14px;
      border: none;
    }
  }

  tbody {
    tr {
      background: rgba(196, 196, 196, 0.13);

      &:nth-child(even) {
        background: rgba(196, 196, 196, 0.28);
      }

      &:hover {
        background: #DFFFF9;
      }
    }
  }
}