@import "variables";

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  border: 1px solid $grey-light !important;
  border-radius: 8px !important;
  padding: $spacing !important;

  @media screen and (max-width: 500px) {
    width: 90% !important;
  }
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}
